/*:root {*/
/*    --primary-color: #E43D12;*/
/*    --secondary-color: #D6536D;*/
/*    --accent-color: #FFA2B6;*/
/*    --highlight-color: #EFB11D;*/
/*    --background-color: #EBE9E1;*/
/*    --text-color: #333;*/
/*    --input-border-color: #ccc;*/
/*    --input-focus-border-color: #E43D12;*/
/*    --button-background-color: #E43D12;*/
/*    --button-hover-background-color: #D6536D;*/
/*    --button-text-color: #fff;*/
/*    --error-color: #E43D12;*/
/*    --success-color: #28a745;*/
/*}*/

/** {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    box-sizing: border-box;*/
/*    position: relative;*/
/*}*/

/*html, body {*/
/*    height: 100%;*/
/*    overflow: auto;*/
/*}*/

/*body {*/
/*    font-family: Arial, sans-serif;*/
/*    color: var(--text-color);*/
/*    background-color: var(--background-color);*/
/*}*/

/*.form-container {*/
/*    max-width: 800px;*/
/*    margin: 0 auto;*/
/*    padding: 20px;*/
/*    border: 1px solid var(--input-border-color);*/
/*    border-radius: 5px;*/
/*    background-color: var(--background-color);*/
/*}*/

/*.form-group {*/
/*    margin-bottom: 15px;*/
/*}*/

/*.form-group label {*/
/*    display: block;*/
/*    font-weight: bold;*/
/*    margin-bottom: 5px;*/
/*    color: var(--primary-color);*/
/*}*/

/*.form-group input[type="text"],*/
/*.form-group input[type="email"],*/
/*.form-group input[type="password"],*/
/*.form-group input[type="file"],*/
/*.form-group textarea,*/
/*.form-group select {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    border: 1px solid var(--input-border-color);*/
/*    border-radius: 4px;*/
/*    font-size: 16px;*/
/*    color: var(--text-color);*/
/*}*/

/*.form-group textarea {*/
/*    resize: vertical;*/
/*}*/

/*.form-group input:focus,*/
/*.form-group textarea:focus,*/
/*.form-group select:focus {*/
/*    outline: none;*/
/*    border-color: var(--input-focus-border-color);*/
/*}*/

/*.form-group button {*/
/*    padding: 10px 20px;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    background-color: var(--button-background-color);*/
/*    color: var(--button-text-color);*/
/*    cursor: pointer;*/
/*    font-size: 16px;*/
/*}*/

/*.form-group button:hover {*/
/*    background-color: var(--button-hover-background-color);*/
/*}*/

/*.section-title {*/
/*    font-size: 1.5em;*/
/*    margin-bottom: 10px;*/
/*    border-bottom: 2px solid var(--primary-color);*/
/*    padding-bottom: 5px;*/
/*    color: var(--primary-color);*/
/*}*/

/*!* Style for the seller registration container *!*/
/*.seller-registration-container {*/
/*    padding: 20px;*/
/*    background-color: var(--background-color);*/
/*    max-width: 1200px;*/
/*    margin: 20px auto;*/
/*    border-radius: 8px;*/
/*    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
/*}*/

/*!* Style for the title *!*/
/*.seller-registration-container .title {*/
/*    color: var(--primary-color);*/
/*    font-size: 2rem;*/
/*    margin-bottom: 20px;*/
/*    text-align: center;*/
/*}*/

/*!* Style for form elements *!*/
/*.seller-registration-container input,*/
/*.seller-registration-container textarea,*/
/*.seller-registration-container select {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    margin-bottom: 10px;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 4px;*/
/*    font-size: 1rem;*/
/*}*/

/*!* Style for file input *!*/
/*.seller-registration-container input[type="file"] {*/
/*    padding: 5px;*/
/*}*/

/*!* Style for buttons *!*/
/*.seller-registration-container .submit-button,*/
/*.seller-registration-container button {*/
/*    background-color: var(--primary-color);*/
/*    color: #fff;*/
/*    padding: 10px 20px;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    cursor: pointer;*/
/*    font-size: 1rem;*/
/*    transition: background-color 0.3s ease;*/
/*}*/

/*.seller-registration-container .submit-button:hover,*/
/*.seller-registration-container button:hover {*/
/*    background-color: var(--secondary-color);*/
/*}*/

/*!* Style for error and success messages *!*/
/*.seller-registration-container .error-message,*/
/*.seller-registration-container .success-message {*/
/*    color: var(--accent-color);*/
/*    font-size: 1rem;*/
/*    margin-top: 10px;*/
/*}*/

/*!* Style for JWT token display *!*/
/*.seller-registration-container .jwt-token {*/
/*    margin-top: 10px;*/
/*    font-size: 1rem;*/
/*    color: var(--highlight-color);*/
/*    word-break: break-all;*/
/*}*/

/*.products-container {*/
/*    margin-bottom: 20px;*/
/*}*/

/*.section-title {*/
/*    font-size: 1.5em;*/
/*    margin-bottom: 10px;*/
/*    border-bottom: 2px solid var(--primary-color);*/
/*    padding-bottom: 5px;*/
/*    color: var(--primary-color);*/
/*}*/

/*.product-item {*/
/*    margin-bottom: 20px;*/
/*    padding: 10px;*/
/*    border: 1px solid var(--input-border-color);*/
/*    border-radius: 5px;*/
/*    background-color: var(--background-color);*/
/*}*/

/*.form-input,*/
/*.form-textarea {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    border: 1px solid var(--input-border-color);*/
/*    border-radius: 4px;*/
/*    font-size: 16px;*/
/*    color: var(--text-color);*/
/*    margin-bottom: 10px;*/
/*}*/

/*.form-input:focus,*/
/*.form-textarea:focus {*/
/*    outline: none;*/
/*    border-color: var(--input-focus-border-color);*/
/*}*/

/*.image-upload {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.image-upload input[type="file"] {*/
/*    flex-grow: 1;*/
/*    margin-right: 10px;*/
/*}*/

/*.add-button,*/
/*.remove-button {*/
/*    background-color: var(--button-background-color);*/
/*    color: var(--button-text-color);*/
/*    padding: 10px 20px;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    cursor: pointer;*/
/*    font-size: 16px;*/
/*    transition: background-color 0.3s ease;*/
/*    margin-right: 10px;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.add-button:hover,*/
/*.remove-button:hover {*/
/*    background-color: var(--button-hover-background-color);*/
/*}*/

/*!* Style for the categories container *!*/
/*.categories-container {*/
/*    margin-bottom: 20px;*/
/*    padding: 20px;*/
/*    border: 1px solid var(--input-border-color);*/
/*    border-radius: 5px;*/
/*    background-color: var(--background-color);*/
/*}*/

/*.section-title {*/
/*    font-size: 1.5em;*/
/*    margin-bottom: 10px;*/
/*    border-bottom: 2px solid var(--primary-color);*/
/*    padding-bottom: 5px;*/
/*    color: var(--primary-color);*/
/*}*/

/*!*.categories-list {*!*/
/*!*    display: grid;*!*/
/*!*    grid-template-columns: repeat(4, 1fr);*!*/
/*!*    gap: 10px;*!*/
/*!*    list-style: none;*!*/
/*!*    padding: 0;*!*/
/*!*    margin: 0;*!*/
/*!*}*!*/
/*.categories-list{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: space-evenly;*/
/*    padding: 20px;*/
/*}*/
/*.category-item {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: start;*/
/*    flex-direction: column;*/
/*}*/

/*.category-checkbox {*/
/*    margin-right: 10px;*/
/*}*/

/*.category-label {*/
/*    font-size: 1.3rem;*/
/*    font-weight: bold;*/
/*    margin-bottom: 0.5rem;*/
/*    color: var(--text-color);*/
/*    cursor: pointer;*/
/*}*/

/*.social-media-links-container {*/
/*    margin-bottom: 20px;*/
/*    padding: 20px;*/
/*    border: 1px solid var(--input-border-color);*/
/*    border-radius: 5px;*/
/*    background-color: var(--background-color);*/
/*}*/

/*.social-media-link-item {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    margin-bottom: 10px;*/
/*}*/

/*.form-select,*/
/*.form-input {*/
/*    flex-grow: 1;*/
/*    padding: 10px;*/
/*    border: 1px solid var(--input-border-color);*/
/*    border-radius: 4px;*/
/*    font-size: 16px;*/
/*    color: var(--text-color);*/
/*    margin-right: 10px;*/
/*}*/

/*.form-select:focus,*/
/*.form-input:focus {*/
/*    outline: none;*/
/*    border-color: var(--input-focus-border-color);*/
/*}*/

/*.add-button,*/
/*.remove-button {*/
/*    background-color: var(--button-background-color);*/
/*    color: var(--button-text-color);*/
/*    padding: 10px 20px;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    cursor: pointer;*/
/*    font-size: 16px;*/
/*    transition: background-color 0.3s ease;*/
/*    margin-right: 10px;*/
/*}*/

/*.add-button:hover,*/
/*.remove-button:hover {*/
/*    background-color: var(--button-hover-background-color);*/
/*}*/


/*.contact-information-container {*/
/*    margin-bottom: 20px;*/
/*    padding: 20px;*/
/*    border: 1px solid var(--input-border-color);*/
/*    border-radius: 5px;*/
/*    background-color: var(--background-color);*/
/*}*/

/*.contact-information-container .form-input {*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    border: 1px solid var(--input-border-color);*/
/*    border-radius: 4px;*/
/*    font-size: 16px;*/
/*    color: var(--text-color);*/
/*    margin-bottom: 10px;*/
/*}*/

/*.contact-information-container .form-input:focus {*/
/*    outline: none;*/
/*    border-color: var(--input-focus-border-color);*/
/*}*/

/*!* Style for error and success messages *!*/
/*.seller-registration-container .error-message,*/
/*.seller-registration-container .success-message {*/
/*    font-size: 1rem;*/
/*    margin-top: 10px;*/
/*}*/

/*.error-message {*/
/*    color: var(--error-color);*/
/*}*/

/*.success-message {*/
/*    color: var(--success-color);*/
/*}*/

/*!* Style for the categories container *!*/
/*.categories-container {*/
/*    margin-bottom: 20px;*/
/*}*/

/*!* Style for the categories list *!*/
/*.categories-list {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(4, 1fr);*/
/*    gap: 20px;*/
/*}*/

/*!* Style for the category item *!*/
/*.category-item {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*!* Checkbox styling *!*/
/*.checkbox-wrapper-21 .control {*/
/*    display: block;*/
/*    position: relative;*/
/*    padding-left: 30px;*/
/*    cursor: pointer;*/
/*    font-size: 18px;*/
/*}*/

/*.checkbox-wrapper-21 .control input {*/
/*    position: absolute;*/
/*    z-index: -1;*/
/*    opacity: 0;*/
/*}*/

/*.checkbox-wrapper-21 .control__indicator {*/
/*    position: absolute;*/
/*    top: 2px;*/
/*    left: 0;*/
/*    height: 20px;*/
/*    width: 20px;*/
/*    background: #e6e6e6;*/
/*}*/

/*.checkbox-wrapper-21 .control:hover input ~ .control__indicator,*/
/*.checkbox-wrapper-21 .control input:focus ~ .control__indicator {*/
/*    background: #ccc;*/
/*}*/

/*.checkbox-wrapper-21 .control input:checked ~ .control__indicator {*/
/*    background: #2aa1c0;*/
/*}*/

/*.checkbox-wrapper-21 .control:hover input:not([disabled]):checked ~ .control__indicator,*/
/*.checkbox-wrapper-21 .control input:checked:focus ~ .control__indicator {*/
/*    background: #0e647d;*/
/*}*/

/*.checkbox-wrapper-21 .control input:disabled ~ .control__indicator {*/
/*    background: #e6e6e6;*/
/*    opacity: 0.6;*/
/*    pointer-events: none;*/
/*}*/

/*.checkbox-wrapper-21 .control__indicator:after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    display: none;*/
/*}*/

/*.checkbox-wrapper-21 .control input:checked ~ .control__indicator:after {*/
/*    display: block;*/
/*}*/

/*.checkbox-wrapper-21 .control--checkbox .control__indicator:after {*/
/*    left: 8px;*/
/*    top: 4px;*/
/*    width: 3px;*/
/*    height: 8px;*/
/*    border: solid #fff;*/
/*    border-width: 0 2px 2px 0;*/
/*    transform: rotate(45deg);*/
/*}*/

/*.checkbox-wrapper-21 .control--checkbox input:disabled ~ .control__indicator:after {*/
/*    border-color: #7b7b7b;*/
/*}*/

