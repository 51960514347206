/*.report-button {*/
/*    position: absolute;*/
/*    top: 5px;*/
/*    right: 10px;*/
/*    background-color: red;*/
/*    color: white;*/
/*    border: none;*/
/*    padding: 5px;*/
/*    cursor: pointer;*/
/*    visibility: hidden;*/
/*}*/

/*.message:hover .report-button {*/
/*    visibility: visible;*/
/*}*/



/*.report-textarea {*/
/*    width: 100%;*/
/*    height: 100px;*/
/*    margin-bottom: 15px;*/
/*}*/

/*.submit-button, .cancel-button {*/
/*    padding: 8px 15px;*/
/*    margin-right: 10px;*/
/*    cursor: pointer;*/
/*}*/

.margin-bottom-10{
    margin-bottom: 10px;
}