/* Poppins - Variable Font */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100 900; /* Weight range for variable font */
    src: url('../../public/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../../public/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}
/* Quicksand - Variable Font */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 100 900; /* Weight range for variable font */
    src: url('../../public/fonts/Quicksand/Quicksand-VariableFont_wght.ttf') format('truetype');
}

/* Lora - Variable Font */
@font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 100 700; /* Weight range for variable font */
    src: url('../../public/fonts/Lora/Lora-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Lora';
    font-style: italic;
    font-weight: 100 700; /* Weight range for variable font */
    src: url('../../public/fonts/Lora/Lora-Italic-VariableFont_wght.ttf') format('truetype');
}

/* Raleway - Variable Font */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 100 900; /* Weight range for variable font */
    src: url('../../public/fonts/Raleway/Raleway-VariableFont_wght.ttf') format('truetype');
}

/* Nunito - Variable Font */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 100 900; /* Weight range for variable font */
    src: url('../../public/fonts/Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
}
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 900; /* Weight range for variable font */
    src: url('../../public/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
}
@font-face {
    font-family: 'Lobster';
    font-style: normal;
    font-weight: 100 900; /* Weight range for variable font */
    src: url('../../public/fonts/Lobster/Lobster-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Rage';
    font-weight: normal;
    font-style: italic;
    src: url('../../public/fonts/rage-italic/RAGE_1.TTF');
}
@font-face {
    font-family: 'Rage Italic';
    font-weight: normal;
    font-style: italic;
    src: url('../../public/fonts/rage-italic/Rage Italic.ttf');
}