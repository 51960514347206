:root {
    --circle-bg-color: #789DBC; /* Circle background */
    --products-btn-color: #FFE3E3; /* Products button color */
    --services-btn-color: #C9E9D2; /* Services button color */
    --search-btn-color: #FFE3E3; /* Search button color */
    --h2-color: #FFE3E3; /* h2 text color */
    --svg-text-color: #C9E9D2; /* SVG text color */
    --p-text-color: #C9E9D2; /* Paragraph text color */
    --h3-color: #F49ABE; /* h3 text color */
    --border-color: #F49ABE; /* Border color for selected (circle and search button) */
    --products-btn-text: #B23278; /* Products button text color */
    --services-btn-text: #253329; /* Services button text color */
    --circle-border-color: #B23278; /* Circle border color based on selection */
}
body {
    overflow-x: hidden; /* Prevent horizontal overflow */
}
/* Custom CSS */
.main-section {
    position: relative;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Quicksand', sans-serif;

    border-top: 0.5em solid black; /* Use dynamic border color */
    border-bottom: 0.5em solid black; /* Use dynamic border color */
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.circle {
    background-color: var(--circle-bg-color); /* Use dynamic circle background */
    border: 0.15vw solid var(--circle-border-color); /* Border color for the circle */
    border-radius: 50%;
    width: clamp(42em, 47vw, 47em);  /* Width scales with viewport width */
    height: clamp(42em, 47vw, 47em); /* Maintain a proportional height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    bottom: -20vh;  /* Adjust position relative to the viewport height */


}
.circle h1 {
    font-size: 1.5rem;
    font-weight: bold;
}

/* Styling for the SVG Text */
#thehomemadeshop{
    position: absolute;
    top: 2%; /* Position the SVG above the circle */
    left: 50%;
    transform: translateX(-50%);
    width: clamp(20em, 35vw, 40em);
    height: auto;
    z-index: 2;

}

#thehomemadeshop text {
    font-family: 'Rage Italic', cursive;
    font-size: clamp(3rem, 6vw, 4.5rem); /* Larger responsive text sizing */
    font-weight: 600;
    fill: var(--svg-text-color);
}

.header-text {
    font-family: 'Rage Italic', cursive;
    font-size: clamp(2rem, 4vw, 3rem); /* Responsive font size with clamp */
    font-weight: 400;
    margin-top: -1em;
    color: var(--h2-color);

}

#h3-header{
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    color: var(--h3-color);
    margin-top: 0.5em;
    font-size: clamp(0.85rem, 2.05vw, 1.25rem); /* Adjust these values */
}

.header-text {
    font-family: 'Rage Italic', cursive;
    font-size: 3rem; /* Adjust size as needed */
    font-weight: 400; /* Light weight for a more elegant look */
    margin-top: -1em;
    color: var(--h2-color); /* Use dynamic h2 color */
}

h3 {
    font-size: 1.5rem;
    font-family: 'Quicksand', sans-serif;
    color: var(--h3-color); /* Use dynamic h3 color */
}

.main-section .buttons {
    z-index: 10;
    display: flex;
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    gap: 2em; /* Increase the space between the buttons */
    margin-top: 1.5em; /* Add some space above the buttons */
}

.main-section .button {
    font-family: 'Quicksand', sans-serif;
    padding: 2vh 3vw;  /* Use viewport height and width units for padding */
    font-size: clamp(1rem, 2.5vw, 1.2rem); /* Responsive button text size */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.main-section .button-products {
    font-family: 'Quicksand', sans-serif;
    background-color: var(--products-btn-color); /* Use dynamic products button color */
    color: var(--products-btn-text); /* Use dynamic products button text color */
    border: 2px solid #B23278; /* Static border color for product button */
    text-transform: uppercase;
}

.main-section .button-services {
    font-family: 'Quicksand', sans-serif;
    background-color: var(--services-btn-color); /* Use dynamic services button color */
    color: var(--services-btn-text); /* Use dynamic services button text color */
    border: 2px solid #253329; /* Static border color for service button */
    text-transform: uppercase;
}

/* Optional: Hover effect */
.main-section .button:hover {
    opacity: 0.9; /* Slight transparency on hover */
}

.main-section .zip-text {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.2rem; /* Increase the font size */
    font-weight: 500; /* Medium weight for better readability */
    margin-top: 1em;
    text-align: center; /* Center the text */
    color: var(--p-text-color); /* Use dynamic paragraph text color */
}

.main-section .input-container {
    margin-top: 1rem;
}

.main-section .input-container input {
    font-family: 'Quicksand', sans-serif;
    padding: 1vh;  /* Height relative padding */
    width: 15vw;  /* Responsive width */
    border: 0.2vw solid #ccc;  /* Border scaled relative to viewport width */
}

.main-section .input-container button {
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    padding: 1vh 1.3vw;
    background-color: var(--search-btn-color);
    color: var(--search-btn-text);
    border: 0.2vw solid var(--circle-border-color);
    border-radius: 0;
    cursor: pointer;
    text-transform: uppercase;
}

.main-section .white-cover {
    background-color: white;
    height: 30vh; /* Set the height to 20% of the viewport height */
    position: relative;
    z-index: 5; /* Ensure it covers the part of the circle extending below */
    width: 100%;
    top: 0; /* Move the div up by 20vh to cover the circle */
}
.main-section p{
    font-size: clamp(0.6rem, 1.5vw, 0.95rem); /* Responsive button text size */
}
.main-section p:first-of-type{
    font-size: 1.2em;
    font-weight: 500;
}
.main-section p:nth-of-type(2){
    font-weight: 500;
}