.homePage-cards-container {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    margin-top: 0;
    padding: 0 1em;
    width: 80%;
    margin: auto;
}

.homePage-card {
    border-radius: 0;
    overflow: hidden;
    width: 12vw;
    text-align: center;
    transition: transform 0.3s ease;
    padding: 1vw;
    background-color: var(--secondary-product-color); /* Default to product */
    border: 2px solid black; /* Default to product */
    color: var(--primary-product-color); /* Default to product */
}

.homePage-card-up {
    transform: translateY(-1em);
}

.homePage-card-down {
    transform: translateY(1em);
}

.homePage-card:hover {
    transform: scale(1.05);
}

.homePage-card-title {
    font-family: 'Rage Italic', cursive;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1em;
    color: var(--primary-product-color); /* Default to product */
    width: 100%;
}

.homePage-card-image {
    width: 8em;
    height: auto;
    object-fit: cover;
}
