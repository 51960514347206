@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    overflow-x: hidden; /* Prevent horizontal overflow */
}

:root {
    /* Primary Color Variables for Products */
    --primary-product-color: #B22a7a;
    --secondary-product-color: #f49ac1;
    --tertiary-product-color: #F0FCF8;
    --text-product-color: #B23278;

    /* Primary Color Variables for Services */
    --primary-service-color: #1e3f16;
    --secondary-service-color: #C5E09D;
    --tertiary-service-color: #F0FCF8;
    --text-service-color: #253329;

    /* Shared Colors */
    --btn-hover-opacity: 0.9;
}

.whitespace-normal {
    word-break: break-word;
}

@layer components {
    /* Primary Button */
    .custom-btn-primary {
        @apply bg-secondaryServiceColor text-primaryProductColor font-poppins font-bold py-2 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:bg-secondaryProductColor hover:text-primaryServiceColor hover:shadow-xl;
    }

    /* Secondary Button */
    .custom-btn-secondary {
        @apply bg-lightBrown text-white font-poppins font-bold py-2 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:bg-orange-400 hover:shadow-xl;
    }

    /* Outline Button */
    .custom-btn-outline {
        @apply border-2 border-darkBrown text-darkBrown font-montserrat font-semibold py-2 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:bg-darkBrown hover:text-white;
    }

    /* Remove Button (Red) */
    .custom-btn-remove {
        @apply bg-customRed text-white font-poppins font-bold py-2 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:bg-red-700 hover:shadow-xl;
    }

    /* Critical Action Button (Pink-Red) */
    .custom-btn-critical {
        @apply bg-customPinkRed text-white font-poppins font-bold py-2 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:bg-pink-700 hover:shadow-xl;
    }
}
@layer components {
    .toggle-switch {
        @apply relative inline-flex items-center cursor-pointer;
    }
    .toggle-track {
        @apply w-12 h-6 bg-gray-300 rounded-full transition-colors duration-300 ease-in-out;
    }
    .toggle-thumb {
        @apply absolute w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out;
    }
    .toggle-checked .toggle-track {
        @apply bg-primaryGreen;
    }
    .toggle-checked .toggle-thumb {
        @apply translate-x-6;
    }
}
@layer utilities {
    /* Background 1: Gradient with subtle overlay */
    .custom-bg-1 {
        @apply bg-gradient-to-br from-primaryGreen via-bgGrayOrange to-lightBrown;
        background-size: 200% 200%;
        animation: gradientShift 15s ease infinite;
    }

    /* Background 2: Linear gradient with diagonal lines */
    .custom-bg-2 {
        background: linear-gradient(135deg, #acc1b3 25%, transparent 25%),
        linear-gradient(225deg, #d5b496 25%, transparent 25%),
        linear-gradient(45deg, #d5b496 25%, transparent 25%),
        linear-gradient(315deg, #acc1b3 25%, #d9d4c8 25%);
        background-size: 60px 60px;
        background-position: 0 0, 0 30px, 30px -30px, -30px 0px;
    }

    /* Background 3: Custom radial gradient with overlay */
    .custom-bg-3 {
        background: radial-gradient(circle, #acc1b3, #d6536d, #3f3423);
    }

    @keyframes gradientShift {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
    }
}

@layer components {
    /* Utility to hide the logo below 1250px screen size */
    @media (max-width: 1250px) {
        .hidden-below-1250 {
            display: none !important;
        }
    }
}

@media (min-width: 640px) and (max-width: 880px) {
    .hide-between-sm-md {
        display: none !important;
    }
}

.leaflet-container {
    height: 100vh;
    width: 100%;
}



/* Apply styles for screens smaller than 380px */
@media (max-width: 380px) {
    /* Shrinking container padding */
    .navbar-container {
        padding: 0.25rem;  /* Reduced padding */
    }

    /* Shrinking logo text */
    .navbar-logo h1 {
        font-size: 1.25rem;  /* Fixed size that is smaller */
    }

    /* Notification and Chat Button */
    .notification-btn,
    .chat-btn {
        padding: 0.25rem;  /* Reduced padding */
        transform: scale(0.75); /* More scaling down */
    }

    /* Menu button */
    .menu-button {
        padding: 0.25rem;  /* Reduced padding */
        font-size: 0.80rem;  /* Smaller font size */
    }

    /* Scaling down the icons */
    .icon-small {
        width: 16px;  /* Smaller icon size */
        height: 16px; /* Smaller icon size */
    }

    /* Adjust the dropdown width */
    .dropdown-menu {
        width: auto;  /* Auto width to fit content */
        max-width: 200px;  /* Add max-width to prevent overflow */
    }

    /* Reducing the padding in menu items */
    .dropdown-item {
        padding: 0.5rem;  /* Smaller padding in menu items */
        font-size: 0.70rem;  /* Smaller font size for the menu */
    }
}
.fixed {
    z-index: 9999 !important;
}
